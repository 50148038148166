const reactions = [
  {
    number: 1,
    name: 'tan sao',
    left: true,
    meaning: 'dispersing hand',
    part: 'A',
  },
  {
    number: 2,
    name: 'tan sao',
    left: false,
    meaning: 'dispersing hand',
    part: 'A',
  },
  {
    number: 3,
    name: 'gan sao',
    left: true,
    meaning: 'cultivating arm',
    part: 'A',
  },
  {
    number: 4,
    name: 'gan sao',
    left: false,
    meaning: 'cultivating arm',
    part: 'A',
  },
  {
    number: 5,
    name: 'gam sao',
    left: true,
    meaning: 'pressing hand',
    part: 'A',
  },
  {
    number: 6,
    name: 'gam sao',
    left: false,
    meaning: 'pressing hand',
    part: 'A',
  },
  {
    number: 7,
    name: 'kao sao',
    left: true,
    meaning: 'detaining hand',
    part: 'A',
  },
  {
    number: 8,
    name: 'kao sao',
    left: false,
    meaning: 'detaining hand',
    part: 'A',
  },
  {
    number: 9,
    name: 'fook sao',
    left: true,
    meaning: 'controlling hand',
    part: 'A',
  },
  {
    number: 10,
    name: 'fook sao',
    left: false,
    meaning: 'controlling hand',
    part: 'A',
  },
  {
    number: 11,
    name: 'pak sao',
    left: true,
    meaning: 'slapping hand',
    part: 'B',
  },
  {
    number: 12,
    name: 'pak sao',
    left: false,
    meaning: 'slapping hand',
    part: 'B',
  },
  {
    number: 13,
    name: 'bong sao dessus',
    left: true,
    meaning: 'wing arm',
    part: 'B',
  },
  {
    number: 14,
    name: 'bong sao dessus',
    left: false,
    meaning: 'wing arm',
    part: 'B',
  },
  {
    number: 15,
    name: 'bong sao dessous',
    left: true,
    meaning: 'wing arm',
    part: 'B',
  },
  {
    number: 16,
    name: 'bong sao dessous',
    left: false,
    meaning: 'wing arm',
    part: 'B',
  },
  {
    number: 17,
    name: 'jam sao',
    left: true,
    meaning: 'sinking hand',
    part: 'B',
  },
  {
    number: 18,
    name: 'jam sao',
    left: false,
    meaning: 'sinking hand',
    part: 'B',
  },
  {
    number: 19,
    name: 'kwan sao',
    left: true,
    meaning: 'circling hand',
    part: 'B',
  },
  {
    number: 20,
    name: 'kwan sao',
    left: false,
    meaning: 'circling hand',
    part: 'B',
  },
]

export default reactions
